<template>
  <v-app>
    <v-card class="overflow-hidden" tile elevation="0" height="100%">
      <v-app-bar dense color="primary" dark fixed>
        <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="hide-on-mobile">Descalendrier</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-autocomplete
          v-model="searchModel"
          :disabled="loading"
          :items="searchData"
          color="white lighten-2"
          hide-details
          prepend-icon="mdi-magnify"
          single-line
          placeholder="Chercher une salle, un enseignant..."
          item-text="value"
          item-value="value"
          return-object
          clearable
        >
          <template v-slot:item="data">
            <v-list-item-icon>
              <svg
                v-if="data.item.type === 'Enseignant'"
                style="width:24px; height:24px;"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20,17A2,2 0 0,0 22,15V4A2,2 0 0,0 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9A2,2 0 0,1 3.5,7H15M8,4A2,2 0 0,1 6,6A2,2 0 0,1 4,4A2,2 0 0,1 6,2A2,2 0 0,1 8,4Z"
                />
              </svg>
              <v-icon class="color--black" v-else>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.value"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.type"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-spacer></v-spacer>
        <v-btn icon @click="toggleDark">
          <v-icon v-if="$vuetify.theme.dark">mdi-weather-night</v-icon>
          <v-icon v-if="!$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
        </v-btn>

        <v-dialog v-model="dialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Foire aux Questions</span>
            </v-card-title>
            <v-card-text>
              <h3>Qui maintiens cette application ?</h3>
              <p>
                Cette application a été développée par
                <b
                  ><a href="https://github.com/JiveOff"
                    >Antoine (@JiveOff)</a
                  ></b
                >
                spécialement pour le département informatique de l'IUT de Paris.
              </p>
              <h3>Comment les données sont-elles récupérées ?</h3>
              <p>
                Les données sont récupérées via les exports iCal de
                l'application universitaire ADE. Les données restent dans un
                cache pour une durée de 30 minutes mais peuvent rester au-delà
                de cette durée pour servir ces anciennes données afin de
                rafraîchir dans le background. L'application étant très utilisée
                au sein de l'IUT, il est rare que les informations que vous
                obtenez soient dépassées.
              </p>
              <h3>Quelles technologies sont utilisées ?</h3>
              <p>
                L'application est écrite en Vue.js et utilise le framework
                Vuetify pour son affichage. Les données sont récupérées via une
                API REST Serverless qui sert les données des exports en cache.
              </p>
              <h3>Y-a-t'il une application mobile pour Descalendrier ?</h3>
              <p>
                Étant donné la nature "sensible" des données présentes sur
                l'application, aucune publication sur les stores ne se fera
                officiellement. Vous pouvez tout de même utiliser cette
                application sur votre navigateur de smartphone.
              </p>
              <h3>Est-ce que Descalendrier est open-source ?</h3>
              <p>
                Descalendrier et son API sera mis en ligne sur un dépôt Git une fois quelques manipulations faites pour protéger les exports. Vous pouvez tout de même consulter le code source du Front-end sur votre navigateur si vous cherchez bien. 👀
              </p>
              <h3>Il y a un problème, comment puis-je le signaler ?</h3>
              <p>
                J'attend votre mail avec impatience avec les informations techniques ci-dessous à l'adresse suivante:
                <code>antoine@jiveoff.fr</code>
              </p>
                <blockquote>
                  Environnement: <code>{{ env }}</code><br />
                  Build: <code>{{ build }}</code> @ <code>{{ provider }}</code><br />
                </blockquote>
              <h3>Remerciements</h3>
              <p>
                <ul>
                  <li><b>Le secrétariat/direction de l'IUT & le BDE Faction</b> pour leur confiance.</li>
                  <li>Les <b>maintainers</b> des librairies utilisés: <code>Vue.js, Vuetify, Axios, Moment, Node-iCal (côté API)</code></li>
                  <li>Les <b>étudiants de la promo 2022</b> pour leur implication & suggestions de fonctionnalités. 🥰</li>
                  <li><b>Jérôme Baton</b> pour sa suggestion pour améliorer l'expérience utilisateur des professeurs sur l'application.</li>
                </ul>
              </p>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialog = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary fixed app>
        <v-list nav dense>
          <v-list-group
            v-for="annee in annees"
            :key="annee.titre"
            :prepend-icon="annee.icone"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="annee.titre"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-group color="primary">
              <v-list-item
                v-for="groupe in annee.groupes"
                :key="groupe.titre"
                @click="setGroupe(groupe)"
                link
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${groupe.titre}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <v-card-text style="margin-top: 48px;">
        <v-alert
          v-if="this.selectedGroupe == null && events.length === 0"
          border="left"
          colored-border
          type="info"
          elevation="1"
        >
          Aucun groupe séléctionné, cliquez
          <strong
            class="primary--text"
            style="cursor: pointer;"
            @click="openDrawer"
            >ici</strong
          >
          pour en selectionner un.
        </v-alert>

        <v-alert
          v-if="this.selectedGroupe != null && events.length === 0"
          border="left"
          colored-border
          type="error"
          elevation="1"
        >
          Aucun cours trouvé pour <b>{{ selectedGroupe.titre }}</b
          >.
        </v-alert>

        <v-alert
          v-if="searchModel"
          border="left"
          colored-border
          type="warning"
          elevation="1"
        >
          <strong>Attention!</strong> Ces emplois du temps ne concernent que le
          département informatique et n'affichent pas les cours des autres
          départements.
        </v-alert>

        <div
          v-if="events.length === 0"
          style="display: flex; flex-flow: column wrap; justify-content: center; align-content: center; min-height: 50vh; text-align: center"
        >
          <v-icon color="primary" size="124px">mdi-calendar-check</v-icon><br />
          <h1 class="primary--text" style="display: block">Descalendrier</h1>
          <br />
          <span>Votre emploi du temps à l'IUT de Paris</span>
        </div>

        <v-row v-if="events.length !== 0">
          <v-col>
            <h1 class="black--text--lighten mb-5 mt-3">
              {{ selectedText }}<br /><small
                style="font-weight: 300; font-size: 20px; display: block"
                class="mt-2"
                >Cliquez sur les cours pour plus d'informations.</small
              >
            </h1>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Jour</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semaine</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mois</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet
              height="75vh"
              v-touch="{
                left: () => swipe('l'),
                right: () => swipe('r')
              }"
            >
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :event-name="getEventName"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                event-overlap-mode="stack"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="getEventColor(selectedEvent)" dark>
                    <v-btn icon>
                      <svg
                        v-if="
                          selectedEvent.location &&
                            selectedEvent.location
                              .toLowerCase()
                              .indexOf('amphi') > -1
                        "
                        style="width:24px; height:24px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M20,17A2,2 0 0,0 22,15V4A2,2 0 0,0 20,2H9.46C9.81,2.61 10,3.3 10,4H20V15H11V17M15,7V9H9V22H7V16H5V22H3V14H1.5V9A2,2 0 0,1 3.5,7H15M8,4A2,2 0 0,1 6,6A2,2 0 0,1 4,4A2,2 0 0,1 6,2A2,2 0 0,1 8,4Z"
                        />
                      </svg>
                      <v-icon v-else>mdi-book-open-page-variant</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="selectedEvent.classes">{{
                      selectedEvent.location.toLowerCase().indexOf("amphi") > -1
                        ? selectedEvent.summary + " (amphithéatre)"
                        : selectedEvent.summary
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <span v-if="selectedEvent.classes" style="color: #777">
                      <span v-if="selectedEvent.location.split(',').length > 1">
                        Assuré
                        {{ getMomentFromNow(selectedEvent.start) }}.<br />
                        Salles reservées:
                        <ul>
                          <li
                            v-for="salle in selectedEvent.location.split(',')"
                            :key="salle"
                          >
                            <b>{{ salle }}</b>
                          </li>
                        </ul>
                      </span>
                      <span v-else>
                        Assuré en <b>{{ selectedEvent.location }}</b>
                        {{ getMomentFromNow(selectedEvent.start) }}.<br />
                      </span>
                      <span v-if="selectedEvent.enseignant.length > 0"
                        >Enseignant: <b>{{ selectedEvent.enseignant }}</b
                        ><br
                      /></span>
                      <span
                        v-if="selectedEvent.classes.length > 1 || searchModel"
                      >
                        Groupe(s) concerné(s):
                        <span
                          v-if="
                            selectedEvent.classes[
                              selectedEvent.classes.length - 1
                            ] === '112' && selectedEvent.classes.length === 12
                          "
                        >
                          <b>Tous les 1ères années</b>
                        </span>
                        <span
                          v-else-if="
                            selectedEvent.classes[
                              selectedEvent.classes.length - 1
                            ] === '209' && selectedEvent.classes.length === 9
                          "
                        >
                          <b>Tous les 2èmes années FI</b>
                        </span>
                        <ul v-else-if="selectedEvent.classes.length > 0">
                          <li
                            v-for="class_sel in selectedEvent.classes"
                            :key="class_sel"
                          >
                            <b>{{ class_sel }}</b>
                          </li>
                        </ul>
                        <span v-else>
                          <b>{{ selectedEvent.parent }}</b>
                        </span>
                      </span>
                    </span>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer :absolute="false" :fixed="false" :padless="true">
      <v-card flat tile width="100%" class="primary text-center">
        <v-divider></v-divider>
        <v-card-text class="white--text">
          Descalendrier — Réalisé par
          <strong
            ><a style="color: white;" href="https://github.com/JiveOff"
              >Antoine (@JiveOff)</a
            ></strong
          >
          pour le département informatique de l'IUT de Paris.
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import colors from "vuetify/lib/util/colors";
import moment from "moment";

export default {
  name: "App",

  data() {
    let env = process.env.VUE_APP_VERCEL_ENV || "local";
    let build = process.env.VUE_APP_VERCEL_GIT_COMMIT_SHA || "local";
    let provider = process.env.VUE_APP_VERCEL_GIT_PROVIDER || "git";

    return {
      env,
      build,
      provider,

      drawer: false,

      selectedText: "Aucun groupe séléctionné.",
      focus: "",
      type: "day",
      typeToLabel: {
        month: "Mois",
        week: "Semaine",
        day: "Jour"
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],

      loading: false,
      snackbar: false,
      snackbarMessage: "",

      selectedGroupe: null,
      selectedYear: 0,
      annees: [],

      allEvents: [],

      searchData: [],
      searchModel: null,

      dialog: false,

      searchables: {
        salles: [],
        profs: []
      }
    }
  },
  async mounted() {
    if (localStorage.darkEnabled)
      this.$vuetify.theme.dark =
        localStorage.darkEnabled === "true" ? true : false;

    window.onresize = () => {
      this.type = window.innerWidth > 800 ? "week" : "day";
    };
    this.type = window.innerWidth > 800 ? "week" : "day";

    this.loading = true;
    await this.loadGroupes();
    this.loading = false;

    if (this.getParameterByName("query")) {
      let searchResult = this.searchData.find(
        item =>
          item.value.toLowerCase().trim() ===
          this.getParameterByName("query")
            .toLowerCase()
            .trim()
      );
      if (!searchResult) {
        this.snackbarMessage = "Recherche invalide.";
        this.snackbar = true;
        return;
      }
      this.searchModel = searchResult;
      setTimeout(() => {
        this.$refs.calendar.checkChange();
        this.$refs.calendar.scrollToTime("8:00");
      }, 100);
    } else if (localStorage.groupeSelected)
      await this.setGroupe(JSON.parse(localStorage.groupeSelected));
  },
  watch: {
    async searchModel(val) {
      if (val) {
        if (val.type === "Enseignant") {
          this.selectedText = "Emploi du temps de " + val.value;
        } else {
          this.selectedText = "Emploi du temps de la salle " + val.value;
        }
        this.events = val.cours;
        let params = new URLSearchParams(window.location.search);
        params.set("query", val.value);
        let newRelativePathQuery =
          window.location.pathname + "?" + params.toString();
        history.pushState(null, "", newRelativePathQuery);
      } else {
        history.pushState(null, "", window.location.pathname);
        if (localStorage.groupeSelected)
          await this.setGroupe(JSON.parse(localStorage.groupeSelected));
      }
    }
  },
  methods: {
    openDrawer() {
      window.scrollTo({top: 0});
      this.drawer = true;
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    async loadGroupes() {
      try {
        let req = await axios({
          url: "/api/groupes",
          method: "GET"
        });
        this.annees = req.data;
      } catch (e) {
        console.error(e);
      }

      let searchables = {
        salles: [],
        profs: []
      };

      this.searchData = [];

      this.annees.forEach(annee => {
        annee.groupes.forEach(groupe => {
          groupe.edt.forEach(cours => {
            cours.start = new Date(cours.start);
            cours.end = new Date(cours.end);
            cours.lastmodified = new Date(cours.lastmodified);
            cours.dtstamp = new Date(cours.dtstamp);
            cours.summary = cours.name;

            cours.parent = groupe.titre;

            if (annee.titre === "1ère année") cours.color = "purple";
            else if (annee.titre === "2ème année") cours.color = "green";
            else cours.color = "primary";

            if (cours.location.length > 0)
              if (searchables.salles.indexOf(cours.location) === -1) {
                searchables.salles.push(cours.location);

                if (cours.location.split(",").length > 1) {
                  let salles = cours.location.split(",");
                  // console.log(salles);
                  for (let i in salles) {
                    // console.log(salles[i]);
                    let indexFound = this.searchData.findIndex(
                      search => search.value === salles[i]
                    );
                    if (
                      indexFound > -1 &&
                      !this.searchData[indexFound].cours.filter(
                        ev => ev.start.getTime() === cours.start.getTime()
                      )[0]
                    )
                      this.searchData[indexFound].cours.push(cours);
                    else
                      this.searchData.push({
                        type: "Salle",
                        value: salles[i],
                        cours: [cours]
                      });
                  }
                } else {
                  this.searchData.push({
                    type: "Salle",
                    value: cours.location,
                    cours: [cours]
                  });
                }
              } else {
                let indexFound = this.searchData.findIndex(
                  search => search.value === cours.location
                );
                if (
                  indexFound > -1 &&
                  !this.searchData[indexFound].cours.filter(
                    ev => ev.start.getTime() === cours.start.getTime()
                  )[0]
                )
                  this.searchData[indexFound].cours.push(cours);
              }

            if (cours.enseignant.length > 0) {
              if (cours.enseignant.indexOf(", ")) {
                let enseignants = cours.enseignant.split(", ");
                for (let i in enseignants) {
                  if (searchables.profs.indexOf(enseignants[i]) === -1) {
                    searchables.profs.push(enseignants[i]);
                    this.searchData.push({
                      type: "Enseignant",
                      value: enseignants[i],
                      cours: [cours]
                    });
                  } else {
                    let indexFound = this.searchData.findIndex(
                      search => search.value === enseignants[i]
                    );
                    if (
                      !this.searchData[indexFound].cours.filter(
                        ev => ev.start.getTime() === cours.start.getTime()
                      )[0]
                    )
                      this.searchData[indexFound].cours.push(cours);
                  }
                }
              } else {
                if (searchables.profs.indexOf(cours.enseignant) === -1) {
                  searchables.profs.push(cours.enseignant);
                  this.searchData.push({
                    type: "Enseignant",
                    value: cours.enseignant,
                    cours: [cours]
                  });
                } else {
                  let indexFound = this.searchData.findIndex(
                    search => search.value === cours.enseignant
                  );
                  if (
                    !this.searchData[indexFound].cours.filter(
                      ev => ev.start.getTime() === cours.start.getTime()
                    )[0]
                  )
                    this.searchData[indexFound].cours.push(cours);
                }
              }
            }
          });
        });
      });
      this.searchables = searchables;
    },
    swipe(direction) {
      if (direction === "l") this.next();
      else if (direction === "r") this.prev();
    },
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.darkEnabled = this.$vuetify.theme.dark;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color || "primary";
    },
    getEventName(event) {
      return event.input.location.toLowerCase().indexOf("amphi") > -1
        ? "Φ " + event.input.summary
        : event.input.summary;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      splitStr = splitStr.join(" ").split("-");
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          "-" + splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join("").slice(1);
    },
    async setGroupe(groupe) {
      this.loading = true;
      localStorage.groupeSelected = JSON.stringify(groupe);

      this.searchModel = null;

      let calendar;
      try {
        let req = await axios({
          url: "/api/edt/" + groupe.titre,
          method: "GET"
        });
        calendar = req.data;
      } catch (e) {
        this.snackbarMessage =
          "Une erreur est survenue: " + e.response.data.err ||
          "Actualisez la page.";
        this.snackbar = true;
        this.loading = false;
        return;
      }

      this.selectedGroupe = groupe;

      let colorsObj = colors;

      let colorsToUse = [];
      for (let [i] of Object.entries(colorsObj)) {
        if (i.toLowerCase() == i) {
          colorsToUse.push(i + " darken-2");
          colorsToUse.push(i + " darken-4");
        }
      }

      let events = [];
      for (let k in calendar) {
        var ev = calendar[k];
        if (calendar[k].type == "VEVENT") {
          ev.name = ev.summary;
          let sameEv = events.filter(e => e.name === ev.name)[0];
          if (sameEv) ev.color = sameEv.color;
          else {
            let colorsTaken = [];
            events.forEach(e => {
              colorsTaken.push(e.color);
            });
            let colorsToPickFrom = colorsToUse.filter(
              color => !colorsTaken.includes(color)
            );
            ev.color = colorsToPickFrom[0];
          }
          ev.timed = true;
          ev.start = new Date(ev.start);
          ev.end = new Date(ev.end);
          ev.lastmodified = new Date(ev.lastmodified);
          ev.dtstamp = new Date(ev.dtstamp);

          if (ev.description) {
            let descriptionFinal = ev.description;
            let descriptionSplit = ev.description
              .replace(/\n/g, "||")
              .split("||");
            let classes = [];
            for (let i in descriptionSplit) {
              if (
                descriptionSplit[i].length === 3 &&
                parseInt(descriptionSplit[i])
              ) {
                classes.push(descriptionSplit[i]);
                descriptionFinal = descriptionFinal.replace(
                  descriptionSplit[i],
                  ""
                );
              }
            }
            ev.classes = classes;

            let lastManip = descriptionFinal
              .trim()
              .slice(0, descriptionFinal.trim().indexOf("("))
              .split(" ");

            if (
              this.selectedGroupe.titre === "Apprentissage" ||
              this.selectedGroupe.titre === "L3 MIAGE" ||
              this.selectedGroupe.titre === "BUT Année Spéciale" ||
              this.selectedGroupe.titre === "LP IOT" ||
              this.selectedGroupe.titre === "LP Génie Logiciel Apprentissage" ||
              this.selectedGroupe.titre === "LP Génie Logiciel Initiale"
            ) {
              lastManip = descriptionSplit.filter(word => word.length > 0);
              lastManip.shift();
              lastManip = lastManip.join(" ");
              lastManip = lastManip
                .slice(0, lastManip.trim().indexOf("("))
                .split(" ")
                .reverse();
            } else {
              lastManip = lastManip.filter(word => word.length > 0);
              lastManip = lastManip
                .join(" ")
                .split("\n")
                .filter(word => word.length > 0);
              // console.log(lastManip);
              for (let i in lastManip) {
                let lst = lastManip[i].split(" ");
                lst.unshift(lst.pop());
                lastManip[i] = lst.join(" ");
              }
              if (lastManip.length > 1) lastManip = [lastManip.join(", ")];
            }

            ev.enseignant = this.titleCase(lastManip.join(" "));
            if (ev.enseignant.indexOf("Ilie") > -1)
              ev.enseignant = "Jean-Michel Ilié";
            if (
              ev.enseignant.indexOf("Mesatfa") > -1 &&
              ev.enseignant.indexOf("Florence") > -1
            )
              ev.enseignant = "Florence Mesatfa-Fessy";
            if (
              ev.enseignant.indexOf("Elsa") > -1 &&
              ev.enseignant.indexOf("Belilovsky") > -1
            )
              ev.enseignant = "Elsa De Belilovsky";
            if (
              ev.enseignant.indexOf("Sebastien") > -1 &&
              ev.enseignant.indexOf("Botton") > -1
            )
              ev.enseignant = "Sebastien De Botton";
          }

          ev.parent = groupe.titre;
          events.push(ev);
        }
      }
      this.selectedText = "Emploi du temps du groupe " + groupe.titre;
      this.drawer = false;
      this.events = events;
      this.loading = false;
      setTimeout(() => {
        this.$refs.calendar.checkChange();
        this.$refs.calendar.scrollToTime("8:00");
      }, 1e3);
    },
    getMomentFromNow(date) {
      let momentLocalized = moment(date);
      momentLocalized.locale("fr");
      return momentLocalized.fromNow();
    }
  }
};
</script>

<style>
h3 {
  font-size: 1.3rem;
  margin-bottom: 0.9rem;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}

blockquote {
  border-left: 5px solid #b71c1c;
  padding: 0.5rem 1rem;
  margin-left: 0;
  margin-right: 0;
  background: #b71c1c33;
  margin-bottom: 1rem;
}
</style>
